@font-face {
  font-family: 'Montserrat-Black';
  src: url('./assets/fonts/Montserrat-Black.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MyriadPro-Bold';
  src: url('./assets/fonts/MyriadPro-Bold.otf') format('opentype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'aliens';
  src: url('./assets/fonts/aliens_and_cows_trial.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow: hidden;
  background-color: #f5f5f5;
  color: #333;
  font-family: 'Montserrat-Black';
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.5px;
  text-align: left;
  text-decoration: none;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100svh;
  width: 100svw;
  z-index: 1;
}

.installation {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('./assets/images/5.svg');
  /* background-image: url('./assets/images/installation-full_smaller.svg'); */
  /* filter: brightness(1.3) opacity(0.6) contrast(0.89) grayscale(0.7) saturate(2.5); */
  image-rendering:auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  opacity: 0;
  animation: zoomIn 3.5s ease-in-out forwards;
  animation-delay: 2.9s;
  z-index: 2;
  /* add little bit  white color to picture */
}

.installation1 {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url('./assets/images/installation-full.svg'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  opacity: 0;
  animation: zoomIn 3.5s ease-in-out forwards;
  animation-delay: 2.9s;
  z-index: 2;
  /* add little bit  white color to picture */
  filter: brightness(1.15);
  transition: all 1.5s ease-in-out;
}


/* animation */
@keyframes zoomIn {
  0% {
    opacity: 1;
    transform: rotate3d(0, 0, 1, 0deg) scale(0.5);
  }
  100% {
    opacity: 1;
    transform: rotate3d(0, 0, 0) scale(1);
    transform: scale(2.7) translate(0px, -15%);
  }
}

/* animation */
@keyframes zoomOut {
  0% {
    transform: rotate3d(0, 0, 0) scale(1);
  }
  100% {
    transform: rotate3d(0, 0, 1, 0deg) scale(0.5);
  }
}

.bgClass {
  z-index: 1;
}

.backGround {
  width: 100%;
  height: 100%;
  z-index: 0;
  background-image: url('./assets/images/bgr.svg');
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  position: absolute;
  transform: scale(1.7, 2.7) translate(0px, -15%);
  transition: all 1.5s ease-in-out;
}

.asd {
  z-index: 2;
}

.leftText {
  z-index: 3;
  color: #fff;
  font-family: 'MyriadPro-Bold';
  font-size: 7px;
}

.centerText {
  z-index: 3;
  color: #fff;
  font-family: 'Montserrat-Black';
  font-size: 15px;
  margin-bottom: 10px;
}

.btn {
  background: transparent;
  border: none
}

.canvasObj {
  /* make it little bit transparent and spreaded like blurr effect */
  opacity: 0.8;
  -moz-filter: blur(5px);
  -webkit-filter: blur(5px);
  -o-filter: blur(5px);
  filter: blur(5px);
}

.dialog {
  display: none; /* Hidden by default */
  opacity: 0; /* Transparent by default */
  position: absolute; /* Position it relative to the viewport */
  background-color: rgba(255, 255, 255, 0.2);
  /* transparent 50% */
  border-radius: 10px;
  /* padding: 20px; */
  width: calc(100% - 40px); /* 100% width - 40px padding */
  height: calc(100% - 120px); /* 100% height - 40px padding */
  z-index: 1000; /* Ensure it sits on top of other elements */
  transition: opacity 1.3s ease-in-out; 
  /* scroll from 30% */
  overflow: scroll;
  font-weight:normal;
  font-weight: bolder !important;
  font-family: 'MyriadPro-Bold' !important;
  font-size: 12px !important;
}

/* Overlay for clicking outside */
.dialog-overlay {
  display: none; /* Hidden by default */
  opacity: 0; /* Transparent by default */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 999; /* Below the dialog */
  transition: opacity 1.3s ease-in-out; 
}

/* Class to make dialog and overlay visible  */
.dialog-open {
  display: flex;  /* Display as flex when open */
  opacity: 2;     /* Fully visible */
}

.dialog-open .dialog,
.dialog-open .dialog-overlay {
  opacity: 2; /* Transition to visible */
}

.vertical {
  z-index:109 ;
  bottom: 0 ;
  right:0 ;
  display:flex;
  justify-content:center;
  margin-bottom: 36%;
  margin-right: 16px ;
  position: relative ;
  transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  position: absolute; 
  opacity:3 ;
  align-items:center;
  color: white;
  font-family: 'MyriadPro-Bold';
  font-weight: 100;
  letter-spacing: 1px;
}

.temuri {
  display: none;
  text-align: center;
  opacity: 0;
  will-change: opacity, display;
  /* transition: all ease-in-out; */
}

.temuri.show {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: all 1.5s ease-in-out;
}

.textParent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text10 {
  text-align: center;
  transition: all 1.5s ease-in-out;
  align-items: center;
  justify-content: center;
  font-weight: bolder !important;
  font-family: 'MyriadPro-Bold' !important;
  font-size: 10px !important;
  letter-spacing: 0px;
}

.hidden {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.show {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.sound-wave-2 {
  width: 60px; /* Adjust for the number of lines */
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.sound-wave-2 .line {
  width: 4px; /* Make the lines thinner */
  height: 5px;
  background-color: rgb(255, 255, 255);
  /* display: inline-block; */
  animation: jump 1.8s infinite alternate;
  border-radius: 16px;
}

.sound-wave-2 .line:nth-child(1) { height: 2px; animation: none; }
.sound-wave-2 .line:nth-child(2) { animation-delay: 0.2s; }
.sound-wave-2 .line:nth-child(3) { animation-delay: 0.4s; }
.sound-wave-2 .line:nth-child(4) { height: 2px; animation: none;  } 

@keyframes jump {
  0% { height: 2px;  }
  20% { height: 10px; }
  40% { height: 8px; }
  60% { height: 3px; }
  80% { height: 12px;  }
  100% { height: 5px;  }
}

.intro {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100svh;
  width: 100svw;
  background-color: #46E8BA;
  animation: intro 3.3s ease-in-out, intro2 3.3s ease-in-out;
  color: #fff;
  font-family: 'MyriadPro-Bold';
  text-align: center;
  font-size: 30px;
  /* letter-spacing: 5px; */
  margin-bottom: 50px;
}

.introFirst {
  /* it should be centered first then it should step aside */
  transform: scale(1, 1.7);
  opacity: 0.8;
  transition: transform 0.5s ease-in-out;
  font-family: 'aliens';
  font-weight: lighter;
  letter-spacing: 0;
  /* font-size: 40px; */
}

.intro .introSecond > .otherBy {
  font-size: 25px;
  font-weight: 100;
  letter-spacing: 4px;
  transform: scale(1, 1);
  opacity: 0.5;
}

.introSecond {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* filter: blur(0.5px); */
}

.intro .introSecond {
  /* blinking animation */
  opacity: 0;
  animation: slideIn 1.2s ease-in-out forwards; /* Animation for sliding in the .introSecond pane */
  animation-delay: 1.6s;
}

.intro .introSecond > .by {
  font-size: 10px;
  opacity: 0.7;
}

.intro .introFirst {
   /* Animation for scaling down the .introFirst pane */
}

@keyframes scaleDown {
  /* it should be centered first then it should step aside */
  0% {
    transform: scale(1, 2.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes intro2 {
  0% {
    filter: blur(0);
  }
  10% {
    filter: blur(0);
  }
  20% {
    filter: blur(0);
  }
  30% {
    filter: blur(0);
  }
  40% {
    filter: blur(0);
  }
  50% {
    filter: blur(0);
  }
  60% {
    filter: blur(0);
  }
  70% {
    filter: blur(0);
  }
  80% {
    filter: blur(0);
  }
  90% {
  }
  100% {
  }
}

/* animation */
@keyframes intro {
  0% {
    z-index: 1000;
    opacity: 1;
  }
  10% {
    z-index: 1000;
    opacity: 1;
  }
  20% {
    z-index: 1000;
    opacity: 1;
  }
  30% {
    z-index: 1000;
    opacity: 1;
  }
  40% {
    z-index: 1000;
    opacity: 1;
  }
  50% {
    z-index: 1000;
    opacity: 1;
  }
  60% {
    z-index: 1000;
    opacity: 1;
  }
  70% {
    z-index: 1000;
    opacity: 1;
  }
  80% {
    z-index: 1000;
    opacity: 1;
  }
  90% {
    z-index: 1000;
    opacity: 1;
  }
  100% {
    z-index: -1;
    opacity: 0;
  }
}

.infoButton {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 999;
  overflow: hidden;
}

.infoTextLeft {
  z-index: 999;
}

@keyframes glitch {
  0% {
    opacity: 2;
    transform: translate(0);
  }
  20% {
    opacity: 0.1;
    transform: translate(-2px, -2px);
  }
  40% {
    opacity: 2.3;
    transform: translate(2px, 2px);
  }
  60% {
    opacity: 0.2;
    transform: translate(-2px, 2px);
  }
  80% {
    opacity: 2;
    transform: translate(2px, -2px);
  }
  100% {
    opacity: 3;
    transform: translate(0);
  }
}
.introFirst span:nth-child(1) {
  animation: glitch 1.5s;
}

.introFirst span:nth-child(2) {
  animation: glitch 1.7s;
}

.introFirst span:nth-child(3) {
  animation: glitch 1.3s linear;
}

.introFirst span:nth-child(4) {
  animation: glitch 1.8s linear;
}

.introFirst span:nth-child(5) {
  animation: glitch 1.6s linear;
}

.introFirst span:nth-child(6) {
  animation: glitch 1.9s linear;
}

.introFirst span:nth-child(7) {
  animation: glitch 2.1s linear;
}

.introFirst span:nth-child(8) {
  animation: glitch 2.3s linear;
}

.introFirst span:nth-child(9) {
  animation: glitch 2.5s linear;
}

.introFirst span:nth-child(10) {
  animation: glitch 2.7s linear;
}

.introFirst span:nth-child(11) {
  animation: glitch 2.1s linear;
}

.introFirst span:nth-child(12) {
  animation: glitch 2.3s linear;
}

.introFirst span:nth-child(13) {
  animation: glitch 1.1s linear;
}

.installationImage {
  position: relative;
  height: 4.5svh;
  width:  4.5svh;
}

.installationImage1 {
  position: relative;
  height: 4.5svh;
  width:  6.5svh;
  transition: all 1.5s ease-in-out;
}

.ball {
  background-color: white;
  border-radius: 50%;
  animation: blink 3s infinite;
  opacity: 0;
  transition: all 2s ease-in-out;
  height: 100%;
  width: 100%;
  top: calc(10svh + 6.8svh);
  right: 0.1svw;
  display: none;
  z-index: 1000; 
}

.ball1 {
  display: flex;
  background-color: transparent;
  border-radius: 50%;
  /* animation: blink1 3s infinite; */
  opacity: 1;
  transition: all 2s ease-in-out;
  height: 100%;
  width: 100%;
  top: calc(10svh + 6.8svh);
  right: 0.1svw;
  display: none;
  text-align: center;
  align-items: center;
  line-height: normal;
  transition: all 1.5s ease-in-out;
  
}

.countDown {
  position: absolute;
  display: flex;
  height: 100%;
  width:  100%;
  top: calc(10svh + 7.9svh);
  right: 0.1svw;
  /* background-color: tomato; */
  border-radius: 50%;
  justify-content: end;
  margin-right: 2.5vw;
  align-items: center;
  font-size: 4px;
  font-weight: 100;
  font-family: 'MyriadPro-Bold';
}

.ball.turnOff {
  animation: turnOFF 3s ease-in-out forwards;
  transition: all 3s ease-in-out;
}

.ball1.turnOff {
  animation: turnOFF 3s ease-in-out forwards;
  transition: all 3s ease-in-out;
}

@keyframes turnOFF {
  0% {
    /* opacity: 1; */
  }
  100% {
    opacity: 0;
  }
}

@keyframes blink1 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
    filter: blur(0.5px);
    -moz-filter: blur(0.5px);
    -webkit-filter: blur(0.5px);
    -o-filter: blur(0.5px);
  }
  50% {
    opacity: 0.25;
    filter: blur(0.5px);
    -moz-filter: blur(0.5px);
    -webkit-filter: blur(0.5px);
    -o-filter: blur(0.5px);
  }
  100% {
    opacity: 0;
    filter: blur(0.5px);
    -moz-filter: blur(0.5px);
    -webkit-filter: blur(0.5px);
    -o-filter: blur(0.5px);
  }
}

.slideUp {
  animation: slideUp 3.5s ease-in-out forwards;
}

@keyframes slideUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.yoCola {
  opacity: 1;
  transition: all 1s ease-in-out;
}

.yoVoice {
  opacity: 1;
  transition: all 1s ease-in-out;
}


.yoMesaj {
  opacity: 1;
  transition: all 1.5s ease-in-out;
}

.clickSpeak {
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;
  margin-bottom: 5px;
  margin-right: 15px;
  font-size: 3px;
  letter-spacing: 0;
}

.click {
  animation: blink33 3s infinite;
}

.speak {
  animation: blink44 3s infinite;
  transition: all 3s ease-in-out;
}

@keyframes blink33 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blink44 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.elementHide {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.canvasHide {
  opacity: 0;
  transition: all 1.5s ease-in-out;
}
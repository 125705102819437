/* App.css */
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  /* Style the entire body with a vibrant gradient background */

/* Style the loader container with absolute positioning */
.loader {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center based on its size */
  width: 80px; /* Adjust size as needed */
  height: 80px;
  border-radius: 50%;
  overflow: hidden; /* Hide overflowing content */
}

.loaderMain {
  display: flex;
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: #46E8BA; /* Gradient background */
}

/* Style the inner element that creates the radiant effect */
.loader .inner {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(circle, #FFF, transparent); /* Radial gradient */
  animation: pulse 2s ease-in-out infinite; /* Pulse animation */
}

/* Create a pulsating animation for the effect */
@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.7;
  }
}
